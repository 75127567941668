import { cssBundleHref } from "@remix-run/css-bundle";
import {
	Link,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	isRouteErrorResponse,
	json,
	useLoaderData,
	useNavigate,
	useRouteError,
	useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { ClientOnly } from "remix-utils/client-only";
import LogRocket from "~/components/controls/logrocket";
import { GlobalPendingIndicator } from "~/components/global-pending-indicator";
import {
	ThemeSwitcherSafeHTML,
	ThemeSwitcherScript,
} from "~/components/theme-switcher/theme-utils";

// import stylesheet from "./globals.css";
import type { LinksFunction, LoaderFunction } from "@remix-run/node";
import "./globals.css";
import { useNonce } from "./hooks/misc";
import { getUserSession } from "./services/auth.server";
import { getEnv } from "./services/env.server";
import { getHints } from "./utils/client-hints";
import { getDomainUrl } from "./utils/misc";

import { HomeIcon } from "@radix-ui/react-icons";
import { ChevronLeftCircle } from "lucide-react";
import { FeatureProvider } from "./components/controls";
import { Toaster } from "./components/ui/toaster";
import { SessionProvider } from "./contexts/session";
import { featureFlags } from "./utils/features";
import { AppRoutes } from "./utils/routes";

import "@fontsource-variable/dm-sans";
// Supports weights 100-900
import "@fontsource-variable/inter";
import "@fontsource-variable/montserrat";
import "@fontsource-variable/raleway";
import "@fontsource/poppins";
import "@fontsource/oxygen";

let fontFamily = "DM Sans Variable, sans-serif";
fontFamily = "Inter Variable, sans-serif";
fontFamily = "Raleway Variable, sans-serif";
fontFamily = "Poppins, sans-serif";
fontFamily = "Oxygen, sans-serif";
// fontFamily = "Montserrat Variable, sans-serif";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";

const queryClient = new QueryClient();

export const links: LinksFunction = () => [
	...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
	// { rel: "stylesheet", href: stylesheet },
];

export const loader: LoaderFunction = async ({ request }) => {
	const session = await getUserSession(request);

	const user = session.get("user");
	const organisations = user?.profiles?.map(
		(profile: any) => profile.organisation,
	);

	return json({
		LOGROCKET_APP_ID: "igfzxv/assuarance-qpabc",
		HOST: request.headers.get("host"),
		session: {
			user: user,
			profile: session.get("profile"),
			organisations: organisations,
		},
		requestInfo: {
			hints: getHints(request),
			origin: getDomainUrl(request),
			path: new URL(request.url).pathname,
		},
		ENV: getEnv(),
	});
};

// export const loader: LoaderFunction = async ({ request }) => {
// 	// get the user from the session
// 	const session = await getUserSession(request);

// 	const user = session.get("user")
// 	const organisations = user?.profiles?.map((profile: any) => profile.organisation)

// 	return json({
// 		LOGROCKET_APP_ID: "igfzxv/assuarance-qpabc",
// 		session: {
// 			user: user,
// 			profile: session.get("profile"),
// 			organisations: organisations,
// 		},
// 		requestInfo: {
// 			hints: getHints(request),
// 			origin: getDomainUrl(request),
// 			path: new URL(request.url).pathname,
// 		},
// 		ENV: getEnv(),
// 	});
// };

function App({
	nonce,
	env,
	LOGROCKET_APP_ID,
	children,
}: {
	nonce?: string;
	env?: any;
	LOGROCKET_APP_ID?: string;
	children: React.ReactNode;
}) {
	return (
		<ThemeSwitcherSafeHTML lang="en" style={{ fontFamily }}>
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
				<ThemeSwitcherScript />
			</head>
			<body>
				<GlobalPendingIndicator />

				{children}
				<Toaster />
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration />
				<ClientOnly>
					{() => <LogRocket appId={LOGROCKET_APP_ID || ""} />}
				</ClientOnly>
				<Scripts />
			</body>
		</ThemeSwitcherSafeHTML>
	);
}

function Root() {
	const { ENV, session, LOGROCKET_APP_ID } = useLoaderData<any>();
	const nonce = useNonce();
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						// With SSR, we usually want to set some default staleTime
						// above 0 to avoid refetching immediately on the client
						staleTime: 60 * 1000,
					},
				},
			}),
	);

	return (
		<App nonce={nonce} env={ENV} LOGROCKET_APP_ID={LOGROCKET_APP_ID}>
			<SessionProvider session={session}>
				<QueryClientProvider client={queryClient}>
					<FeatureProvider featureFlags={featureFlags}>
						<Outlet />
					</FeatureProvider>
				</QueryClientProvider>
			</SessionProvider>
		</App>
	);
}

export default withSentry(Root);

export function ErrorBoundary() {
	const { ENV, LOGROCKET_APP_ID } = useRouteLoaderData<any>("root");
	const nonce = useNonce();
	const error = useRouteError();
	const navigate = useNavigate();
	const goBack = () => navigate(-1);

	let status = 500;
	let message = "An unexpected error occurred.";
	if (isRouteErrorResponse(error)) {
		status = error.status;
		switch (error.status) {
			case 404:
				message = "Page Not Found";
				break;
		}
	} else {
		console.error(error);
	}

	captureRemixErrorBoundaryError(error);

	return (
		<App nonce={nonce} env={ENV} LOGROCKET_APP_ID={LOGROCKET_APP_ID}>
			<div className="flex flex-col items-center justify-center h-screen space-y-10">
				<div className="flex items-center justify-center space-x-4 container">
					<h1 className="font-medium text-xl">{status}</h1>
					<div className="mx-4 h-4 w-[1.5px] bg-foreground" />
					<p className="text-sm">{message}</p>
				</div>
				<div className="container flex space-x-4 mx-auto items-center justify-center">
					<Link
						to={AppRoutes.DASHBOARD}
						className="flex space-x-2 items-center bg-foreground text-background p-2 rounded-md"
					>
						<HomeIcon className="h-4 w-4" />
						<span>Home</span>
					</Link>
					<button
						className="flex space-x-2 items-center bg-accent text-muted-accent p-2 rounded-md"
						onClick={goBack}
					>
						<ChevronLeftCircle className="h-4 w-4" />
						<span>Go Back</span>
					</button>
				</div>
			</div>
		</App>
	);
}
